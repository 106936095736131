import React, { useEffect, useState } from "react";
import axios from "axios";
import HostMenuItem from "./HostMenuItem";
import Spinner from "./Spinner";

const HostsMenuPage = ({ isMobile }) => {
  const [hosts, setHosts] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 10, behavior: "auto" });
    getHosts();
  }, []);

  const getHosts = () => {
    const headers = {
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      userID: process.env.REACT_APP_USER_ID
    };
    axios
      .get(`https://api.thesignal.app/mobile/hosts`, {
        headers
      })
      .then(response => {
        setHosts(response.data);
      })
      .catch(err => {
        alert(err);
      });
  };

  return (
    <div className="hostMenuPage">
      <h3>Hosts</h3>
      {hosts.length ? (
        hosts.map(host => <HostMenuItem host={host} isMobile={isMobile} />)
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default HostsMenuPage;
