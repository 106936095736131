import React from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <nav className="nav">
      <div id="logo">
        <div
          className="socialIcons"
          id="faceIcon"
          onClick={() =>
            window.open("http://www.facebook.com/threshold.fm", "_blank")
          }
        />
        <div
          className="socialIcons"
          id="instaIcon"
          onClick={() =>
            window.open("http://www.instagram.com/threshold.fm", "_blank")
          }
        />
        <div
          className="socialIcons"
          id="discordIcon"
          onClick={() =>
            window.open("https://discord.com/invite/4Mqgtuc", "_blank")
          }
        />
        <div
          className="socialIcons"
          id="patreonIcon"
          onClick={() =>
            window.open("https://patreon.com/thresholdfm", "_blank")
          }
        />
      </div>

      <Link to="/" className="full-logo"></Link>

      <ul>
        {/* <li>
          <Link to="/">Home</Link>
        </li> */}
        <li>
          <Link to="/hosts">Hosts</Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
