import React, { useState } from "react";
import { Link } from "react-router-dom";

const HostMenuItem = ({ host, isMobile }) => {
  const linkToHost = () => {
    return <Link to={`/hosts/${host.profileName}`} />;
  };

  return (
    <section className="profileSection" style={{ marginTop: "2rem" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Link
          to={`/hosts/${host.profileName}`}
          style={{ height: "15rem", width: "15rem" }}
        >
          <img
            src={host.hostArtworkUrl}
            alt="host artwork"
            onClick={() => linkToHost()}
            style={{ height: "15rem", width: "15rem" }}
          ></img>
        </Link>
        <div className="profileDetails">
          <Link to={`/hosts/${host.profileName}`}>
            {" "}
            <h3 onClick={() => linkToHost()}>{host.name}</h3>{" "}
          </Link>
          {isMobile ? null : <p>{host.description}</p>}
          <div className="hostIcons">
            <div
              className="socialIcons"
              id="faceIcon"
              onClick={() => window.open(host.facebookUrl, "_blank")}
            />
            <div
              className="socialIcons"
              id="soundcloudIcon"
              onClick={() => window.open(host.soundcloudUrl, "_blank")}
            />
            <div
              className="socialIcons"
              id="instaIcon"
              onClick={() => window.open(host.instagramUrl, "_blank")}
            />
          </div>
        </div>
      </div>
      {isMobile ? <p>{host.description}</p> : null}
    </section>
  );
};

export default HostMenuItem;
