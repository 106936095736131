import React from "react";
import HeroNowPlaying from "./HeroNowPlaying";
import DisplaySchedule from "./DisplaySchedule";
import AppLinksSection from "./AppLinksSection";
import JoinTheCrewSection from "./JoinTheCrewSection";
import DisplayEpisodes from "./DisplayEpisodes";

const HomePage = ({
  liveButton,
  playLiveHandler,
  nowPlaying,
  nowPlayingDetails,
  schedRef,
  schedInfoRef,
  arcRef,
  nowPlayingHandler,
  play,
  isMobile,
  pause,
  url,
  playing,
  livePlayerTitleHandler
}) => {
  return (
    <div>
      <HeroNowPlaying
        liveButton={liveButton}
        playLiveHandler={playLiveHandler}
        nowPlaying={nowPlaying}
        nowPlayingDetails={nowPlayingDetails}
      />
      <section className="schedule" id="schedule" ref={schedRef}>
        <div className="displaySchedule">
          <DisplaySchedule
            ref={schedInfoRef}
            nowPlaying={nowPlayingHandler}
            livePlayerTitleHandler={livePlayerTitleHandler}
          />
        </div>
      </section>
      <div className="outsideLinks">
        <AppLinksSection />
        <JoinTheCrewSection />
      </div>
      <section className="archive" id="archive" ref={arcRef}>
        <h3>Latest Episodes</h3>
        <DisplayEpisodes
          play={play}
          isMobile={isMobile}
          pause={pause}
          url={url}
          playing={playing}
        />
      </section>
    </div>
  );
};

export default HomePage;
