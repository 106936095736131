import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import AppLinksSection from "./AppLinksSection";
import JoinTheCrewSection from "./JoinTheCrewSection";
import Spinner from "./Spinner";
import hostNameLink from "./Functions/HostNameLink";
import shareHandler from "./Functions/shareHandler";

const EpisodePage = ({ play, pause, url, playing, isMobile }) => {
  let { episodeID } = useParams();
  const [episode, setEpisode] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 10, behavior: "auto" });
    getEpisode(episodeID);
  }, []);

  const getEpisode = episode => {
    const headers = {
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      userID: process.env.REACT_APP_USER_ID
    };

    axios
      .get(
        `https://api.thesignal.app/web/hosts/episodes/?episodeid=${episode}`,
        {
          headers
        }
      )
      .then(response => {
        if (response.data.length) {
          setEpisode(response.data[0]);
        } else {
        }
      })
      .catch(err => {
        alert(err);
      });
  };

  const playHandler = () => {
    if (url === episode.epAudioUrl && playing) {
      pause();
    } else {
      play(episode.epAudioUrl, episode.showName, episode.epTitle);
    }
  };

  const descriptionStyle = {
    fontSize: "13px",
    textAlign: "left",
    marginTop: "0px",
    marginBottom: "1rem"
  };
  const hostNameText = {
    fontSize: "15px",
    textAlign: "left",
    marginLeft: "1rem",
    color: "rgb(166, 167, 167)"
  };
  const epTitleStyle = {
    marginLeft: "1rem",
    letterSpacing: "1px"
  };

  const showNameStyle = {
    marginLeft: "1rem",
    letterSpacing: "1px"
  };
  return (
    <div style={{ minHeight: "100vh" }}>
      <head>
        <title>{episode.epTitle}</title>
        <meta
          name="description"
          content={episode.epDescription}
          id="epPageDescMeta"
        />
        <meta
          property="og:url"
          content={"https://dev.threshold.fm/episodes/" + episode.uploadDate}
          id="epPageUrl"
        />
        <meta
          id="epPageTitleMeta"
          property="og:title"
          content={episode.hostName + " - " + episode.epTitle}
        />
        <meta
          id="epPageImgMeta"
          property="og:image"
          content={episode.epArtUrl}
        />
      </head>
      <MetaTags></MetaTags>
      {episode.epTitle ? (
        <div>
          {isMobile ? (
            //MOBILE VERSION
            <section className="episodePageMobile">
              <img src={episode.epArtUrl} />
              <div className="buttonAndHostAndShow">
                <div
                  className={
                    url === episode.epAudioUrl && playing
                      ? "episodePauseButton"
                      : "episodePlayButton"
                  }
                  onClick={() => playHandler()}
                ></div>
                <div style={{ display: "block", marginLeft: "1rem" }}>
                  <Link to={hostNameLink(episode.hostName)}>
                    <p style={hostNameText}>{episode.hostName}</p>
                  </Link>
                  <div className="episodeTitles">
                    <p style={showNameStyle}>{episode.showName} </p>
                  </div>
                </div>
              </div>
              <div className="episodeTitles">
                <p style={epTitleStyle}> {episode.epTitle}</p>
              </div>

              <h3 style={descriptionStyle} className="episode-description">
                {episode.epDescription}
                <div
                  className="socialIcons"
                  id="shareIcon"
                  onClick={() => shareHandler("episodes", episode.uploadDate)}
                  style={{ marginTop: "1rem", marginLeft: "0px" }}
                />
              </h3>
            </section>
          ) : (
            //DESKTOP VERSION
            <section className="episodePage">
              <div className="episode">
                <img src={episode.epArtUrl} />
                <div
                  className={
                    url === episode.epAudioUrl && playing
                      ? "episodePauseButton"
                      : "episodePlayButton"
                  }
                  onClick={() => playHandler()}
                ></div>
                <div style={{ display: "block" }}>
                  <Link to={hostNameLink(episode.hostName)}>
                    <p style={hostNameText}>{episode.hostName}</p>
                  </Link>
                  <div className="episodeTitles">
                    <p style={showNameStyle}>{episode.showName} </p>
                    <p style={epTitleStyle}> {episode.epTitle}</p>
                  </div>
                </div>
              </div>

              <h3 style={descriptionStyle} className="episode-description">
                {episode.epDescription}
                <div
                  className="socialIcons"
                  id="shareIcon"
                  onClick={() => shareHandler("episodes", episode.uploadDate)}
                  style={{ marginTop: "1rem", marginLeft: "0px" }}
                />
              </h3>
            </section>
          )}
        </div>
      ) : (
        <Spinner />
      )}
      <div className="outsideLinks">
        <AppLinksSection />
        <JoinTheCrewSection />
      </div>
    </div>
  );
};

export default EpisodePage;
