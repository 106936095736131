import "./App.css";
import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import PlayerWrapper from "./PlayerWrapper";
import NavBar from "./NavBar";
import HomePage from "./HomePage";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import HostPage from "./HostPage";
import EpisodePage from "./EpisodePage";
import HostsMenuPage from "./HostsMenuPage";

const App = () => {
  const [playing, setPlaying] = useState(false);
  const [url, setURL] = useState("");
  const [liveButton, setLiveButton] = useState("liveButton");
  const [playerButton, setPlayerButton] = useState("playerPlayButton");
  const [timePlayed, setTimePlayed] = useState("00:00:00");
  const [timeLeft, setTimeLeft] = useState("00:00:00");
  const [duration, setDuration] = useState("0");
  const [progress, setProgress] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [live, setLive] = useState(false);
  const [showName, setShowName] = useState("");
  const [episodeName, setEpisodeName] = useState("");
  const [nowPlaying, setNowPlaying] = useState("");
  const [nowPlayingDetails, setNowPlayingDetails] = useState("");
  const [firstPlay, setFirstPlay] = useState(true);
  const player = useRef(null);
  const schedRef = useRef();
  const schedInfoRef = useRef();
  const arcRef = useRef();
  const htmlPlayer = useRef();
  const isMobile = isMobileDevice();

  function isMobileDevice() {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  }

  const mobileTitleHandler = title => {
    htmlPlayer.current.title = title;
    player.current.title = title;
  };

  useEffect(() => {
    if (firstPlay) {
      htmlPlayer.current.addEventListener("loadeddata", event => {
        setLiveButton("liveButtonPressed");
        setPlayerButton("playerPauseButton");
      });

      htmlPlayer.current.addEventListener("pause", event => {
        setPlayerButton("playerPlayButton");
        console.log("event listener pause");
      });

      htmlPlayer.current.addEventListener("play", event => {
        console.log("event listener play");
      });
    }
  });

  const htmlAudioPlayer = url => {
    setFirstPlay(false);
    setPlaying(false);
    htmlPlayer.current.src = url;
    htmlPlayer.current.play();

    setTimePlayed("LIVE");
    setTimeLeft("LIVE");
  };

  const playLiveHandler = url => {
    if (isMobile) {
      htmlAudioPlayer(url);
    } else {
      setURL(url);
      setPlaying(true);
    }

    if (!live) {
      setLiveButton("liveButtonLoading");
    }
    setLive(true);
    setShowName(nowPlaying);
    mobileTitleHandler(nowPlaying);
    setEpisodeName("");
    setProgress(0);
    schedInfoRef.current.getNowPlaying();
  };

  const playArchiveHandler = (url, showName, episodeName) => {
    htmlPlayer.current.pause();
    setShowName(showName);
    setEpisodeName(episodeName);
    setLiveButton("liveButton");
    mobileTitleHandler(`${showName} - ${episodeName}`);
    setLive(false);
    setURL(url);
    setPlaying(true);
  };

  const audioStartHandler = () => {
    setPlayerButton("playerPauseButton");
    if (live) {
      setLiveButton("liveButtonPressed");
    } else {
      setLiveButton("liveButton");
      savedPositionHandler();
    }
  };

  const savedPositionHandler = () => {
    var playedPositions = {};

    if (localStorage.getItem("playedPositions")) {
      playedPositions = JSON.parse(localStorage.getItem("playedPositions"));
    }

    if (playedPositions[url]) {
      player.current.seekTo(playedPositions[url], "seconds");
    }
  };

  const audioPausedHandler = () => {
    setLiveButton("liveButton");
    setPlayerButton("playerPlayButton");
  };

  const playerButtonHandler = () => {
    if (isMobile) {
      if (live) {
        setPlaying(false);
        console.log(htmlPlayer.current.paused);
        if (!htmlPlayer.current.paused) {
          htmlPlayer.current.pause();
        } else {
          htmlPlayer.current.play();
          setPlayerButton("playerPauseButton");
        }
      } else {
        setPlaying(!playing);
      }
    } else {
      setPlaying(!playing);
    }
  };

  const timeFormatter = time => {
    if (!live) {
      var date = new Date(0);
      date.setSeconds(time);
      var timeString = date.toISOString().substr(11, 8);
      return timeString;
    } else {
      return "LIVE";
    }
  };

  const onProgressHandler = event => {
    if (!seeking) {
      setProgress(event.played * 1000);
      var timeRemaining = Math.round(duration - event.playedSeconds);
      timeRemaining = timeFormatter(timeRemaining);
      var played = timeFormatter(Math.round(event.playedSeconds));
      setTimePlayed(played);
      setTimeLeft(timeRemaining);
      savePositionHandler(event);
    }
  };

  const seekHandler = event => {
    if (!live) {
      setSeeking(false);
      setProgress(event.target.value);
      player.current.seekTo(event.target.value / 1000, "fraction");
    }
  };

  const nowPlayingHandler = (np, details) => {
    setNowPlaying(np);
    setNowPlayingDetails(details);
  };

  const livePlayerTitleHandler = (showName, episodeName) => {
    setShowName(showName);
    setEpisodeName(episodeName);
  };

  const savePositionHandler = e => {
    if (!live) {
      var playedPositions = {};

      if (localStorage.getItem("playedPositions")) {
        playedPositions = JSON.parse(localStorage.getItem("playedPositions"));
      }

      playedPositions[`${url}`] = e.playedSeconds;
      localStorage.setItem("playedPositions", JSON.stringify(playedPositions));
    }
  };

  return (
    <div>
      <header className="site-header"></header>
      <NavBar arcRef={arcRef} schedRef={schedRef} />

      <PlayerWrapper
        playerButton={playerButton}
        playerButtonHandler={playerButtonHandler}
        timePlayed={timePlayed}
        seekHandler={seekHandler}
        progress={progress}
        live={live}
        timeLeft={timeLeft}
        isMobile={isMobile}
        showName={showName}
        episodeName={episodeName}
      />

      <ReactPlayer
        ref={player}
        url={url}
        playing={playing}
        onPlay={() => audioStartHandler()}
        onPause={() => audioPausedHandler()}
        height={0}
        onProgress={event => onProgressHandler(event)}
        width={0}
        onDuration={event => setDuration(event)}
      ></ReactPlayer>

      <audio ref={htmlPlayer} id="htmlPlayer" type="audio"></audio>

      <Switch>
        <Route path="/episodes/:episodeID">
          <EpisodePage
            play={playArchiveHandler}
            isMobile={isMobile}
            pause={playerButtonHandler}
            url={url}
            playing={playing}
          />
        </Route>
        <Route path={`/hosts/:hostName`} exact>
          <HostPage
            play={playArchiveHandler}
            isMobile={isMobile}
            pause={playerButtonHandler}
            url={url}
            playing={playing}
          />
        </Route>
        <Route path="/hosts">
          <HostsMenuPage isMobile={isMobile} />
        </Route>
        <Route path="/">
          <HomePage
            liveButton={liveButton}
            playLiveHandler={playLiveHandler}
            nowPlaying={nowPlaying}
            nowPlayingDetails={nowPlayingDetails}
            schedRef={schedRef}
            schedInfoRef={schedInfoRef}
            nowPlayingHandler={nowPlayingHandler}
            arcRef={arcRef}
            play={playArchiveHandler}
            isMobile={isMobile}
            pause={playerButtonHandler}
            url={url}
            playing={playing}
            livePlayerTitleHandler={livePlayerTitleHandler}
          />
        </Route>
      </Switch>
      <div className="bottomMargin"></div>
    </div>
  );
};

export default App;
