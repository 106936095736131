import React from "react";

const Spinner = () => {
  const spinnerWrappper = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "10rem"
  };

  return (
    <div style={spinnerWrappper}>
      <div className="spinner"></div>
    </div>
  );
};

export default Spinner;
