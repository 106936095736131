import React from "react";

const hostNameLink = hostName => {
  hostName = hostName.replace(/[^A-Z0-9.]/gi, "").toLowerCase();
  const link = `/hosts/${hostName}`;

  return link;
};

export default hostNameLink;
