import React, { Component } from "react";
import axios from "axios";
import HostPageEpisodes from "./HostPageEpisodes";
import Spinner from "./Spinner";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";

class HostPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      episodes: []
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 10, behavior: "auto" });
    const fullUrl = window.location.pathname,
      urlPathname = fullUrl.substr(fullUrl.lastIndexOf("/") + 1);
    this.getHostProfile(urlPathname);
  }

  getHostProfile(host) {
    const headers = {
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      userID: process.env.REACT_APP_USER_ID
    };
    axios
      .get(`https://api.thesignal.app/web/hosts/profile/?profilename=${host}`, {
        headers
      })
      .then(response => {
        this.setState({
          profile: response.data
        });
        this.getHostEpisodes(response.data.name);
      })
      .catch(err => {
        alert(err);
      });
  }

  getHostEpisodes(host) {
    const headers = {
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      userID: process.env.REACT_APP_USER_ID
    };

    host = escape(host);

    axios
      .get(`https://api.thesignal.app/web/hosts/episodes/?hostName=${host}`, {
        headers
      })
      .then(response => {
        if (response.data.length) {
          this.setState({
            episodes: response.data,
            noEpisodes: false
          });
        } else {
          this.setState({
            noEpisodes: true
          });
        }
      })
      .catch(err => {
        alert(err);
      });
  }

  render() {
    const {
      name,
      hostArtworkUrl,
      description,
      facebookUrl,
      instagramUrl,
      soundcloudUrl
    } = this.state.profile;

    return (
      <div style={{ minHeight: "100vh" }}>
        {name ? (
          <div>
            <section className="profileSection">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img src={hostArtworkUrl} alt="host artwork"></img>
                <div className="profileDetails">
                  <h3>{name}</h3>

                  {this.props.isMobile ? null : <p>{description}</p>}
                  <div className="hostIcons">
                    <div
                      className="socialIcons"
                      id="faceIcon"
                      onClick={() => window.open(facebookUrl, "_blank")}
                    />
                    <div
                      className="socialIcons"
                      id="soundcloudIcon"
                      onClick={() => window.open(soundcloudUrl, "_blank")}
                    />
                    <div
                      className="socialIcons"
                      id="instaIcon"
                      onClick={() => window.open(instagramUrl, "_blank")}
                    />
                  </div>
                </div>
              </div>
              {this.props.isMobile ? <p>{description}</p> : null}
            </section>

            {this.state.episodes.length ? (
              <section className="archive" id="archive">
                <HostPageEpisodes
                  play={this.props.play}
                  isMobile={this.props.isMobile}
                  pause={this.props.pause}
                  url={this.props.url}
                  playing={this.props.playing}
                  episodes={this.state.episodes}
                />
              </section>
            ) : (
              <Spinner />
            )}
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

export default HostPage;
