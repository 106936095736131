import React, { Component } from "react";
import axios from "axios";
import ScheduleItem from "./ScheduleItem";

class DisplaySchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: [],
      day: 0
    };
  }

  componentDidMount() {
    this.getNowPlaying();
    this.getSchedule();
  }

  getSchedule() {
    var day = new Date();
    day = day.getDay();
    const dayOfTheWeek = [1, 2, 3, 4, 5, 6, 7];

    const headers = {
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      userID: process.env.REACT_APP_USER_ID
    };

    axios
      .get("https://api.thesignal.app/mobile/schedule", { headers })
      .then(response => {
        var sched = response.data;

        sched = sched.filter(show => {
          return show.appearInSchedule == "TRUE";
        });

        sched = sched.sort((a, b) => {
          return a.hourInt - b.hourInt;
        });

        sched = sched.filter(show => {
          return show.dayInt == dayOfTheWeek[day];
        });

        this.setState({
          schedule: sched
        });
      })
      .catch(err => {
        alert(err);
      });
  }

  getNowPlaying() {
    const day = new Date();
    const UTCday = day.getUTCDay();
    const UTChour = day.getUTCHours() + 1;
    const dayOfTheWeek = [1, 2, 3, 4, 5, 6, 7];
    const theDay = dayOfTheWeek[UTCday];

    const headers = {
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      userID: process.env.REACT_APP_USER_ID
    };
    axios
      .get(
        `https://api.thesignal.app/mobile/nowplaying/?day=${theDay}&hour=${UTChour}`,
        { headers }
      )
      .then(response => {
        response.data.Items.length
          ? this.props.nowPlaying(
              `${response.data.Items[0].showName} with ${response.data.Items[0].hostName}`,
              response.data.Items[0].showDescription
            )
          : this.props.nowPlaying(
              "Shoe Thrower Playlist",
              "Maybe the finest selection of Shoe Throwing anthems ever created. Or not. To be honest I don't really have an accurate frame of reference."
            );
      })
      .catch(err => {
        alert(err);
      });
  }

  render() {
    var sched = this.state.schedule;
    var key = 1;

    return (
      <div>
        <h3>Today's Shows</h3>

        <div className="scheduleText">
          {sched.length ? (
            sched.map(sch => <ScheduleItem key={key++} schedule={sch} />)
          ) : (
            <p>No Shows Today</p>
          )}
        </div>
      </div>
    );
  }
}

export default DisplaySchedule;
