import React from "react";

const JoinTheCrewSection = () => {
  return (
    <section className="outsideLinksSection">
      <h3>Join The Crew</h3>
      <p>
        Be apart of what The Guardian are calling "The early 00's rave answer to
        Heaven's Gate".
      </p>
      <div id="icons">
        <img
          src="https://testingthresholdadmin.s3.eu-west-2.amazonaws.com/siteimages/discord.png"
          className="outsideLinksButtons"
          onClick={() =>
            window.open("https://discord.com/invite/4Mqgtuc", "_blank")
          }
        ></img>
        <img
          src="https://testingthresholdadmin.s3.eu-west-2.amazonaws.com/siteimages/become_a_supporter-button.png"
          className="outsideLinksButtons"
          onClick={() =>
            window.open("https://patreon.com/thresholdfm", "_blank")
          }
        ></img>
      </div>
    </section>
  );
};

export default JoinTheCrewSection;
