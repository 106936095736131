import React from "react";

const AppLinksSection = () => {
  return (
    <section className="outsideLinksSection">
      <h3>Download The Apps</h3>
      <p>
        We demand full access to your life, loves and lobsters. If necessary we
        will take them by force.
      </p>
      <div id="icons">
        <img
          src="https://testingthresholdadmin.s3.eu-west-2.amazonaws.com/siteimages/appstore_real.png"
          className="outsideLinksButtons"
          onClick={() => window.open("https://apple.co/2ENtOwT", "_blank")}
        ></img>
        <img
          src="https://testingthresholdadmin.s3.eu-west-2.amazonaws.com/siteimages/1200px-Google_Play_Store.png"
          className="outsideLinksButtons"
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.ThresholdFM.app",
              "_blank"
            )
          }
        ></img>
      </div>
    </section>
  );
};

export default AppLinksSection;
