import React from "react";

const ScheduleItem = ({ schedule }) => {
  return (
    <div>
      <p>
        {schedule.displayTime}: {schedule.showName} with {schedule.hostName}
      </p>
    </div>
  );
};

export default ScheduleItem;
