import React from "react";

const PlayerWrapper = ({
  playerButton,
  playerButtonHandler,
  timePlayed,
  seekHandler,
  progress,
  live,
  timeLeft,
  isMobile,
  showName,
  episodeName
}) => {
  return (
    <div className="player-wrapper">
      <div className={playerButton} onClick={() => playerButtonHandler()}></div>
      <p>{timePlayed}</p>
      <input
        type="range"
        onChange={event => seekHandler(event)}
        min="1"
        max="1000"
        value={progress}
        className="slider"
        id="myRange"
      ></input>
      <p>
        {live ? "" : "-"}
        {timeLeft}
      </p>

      {isMobile ? null : (
        <div className="playerInfo">
          <p>{showName}</p>
          <p>{episodeName}</p>
        </div>
      )}
    </div>
  );
};

export default PlayerWrapper;
