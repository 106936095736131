import React from "react";
import Spinner from "./Spinner";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const HeroNowPlaying = ({
  liveButton,
  playLiveHandler,
  nowPlaying,
  nowPlayingDetails
}) => {
  return (
    <section className="hero">
      <div
        className={liveButton}
        onClick={() => playLiveHandler("https://s2.radio.co/sdf9aeb4e9/listen")}
      ></div>

      {nowPlaying ? (
        <h3 id="nowPlaying">Now Playing: {nowPlaying}</h3>
      ) : (
        <h3 id="nowPlaying">Now Playing: Shoe Thrower Playlist </h3>
      )}

      {nowPlaying !== "" ? (
        <p id="nowPlayingDetails">{nowPlayingDetails}</p>
      ) : (
        <p id="nowPlayingDetails">
          Maybe the finest selection of Shoe Throwing anthems ever created. Or
          not. To be honest I don't really have an accurate frame of reference.
        </p>
      )}
    </section>
  );
};

export default HeroNowPlaying;
