import React, { useState } from "react";
import { Link } from "react-router-dom";
import hostNameLink from "./Functions/HostNameLink";
import shareHandler from "./Functions/shareHandler";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";

const EpisodeItem = ({ episode, play, pause, url, playing }) => {
  const [showDescription, setShowDescription] = useState(false);

  const playHandler = () => {
    if (url === episode.epAudioUrl && playing) {
      pause();
    } else {
      play(episode.epAudioUrl, episode.showName, episode.epTitle);
    }
  };

  const episodeLink = () => {
    return `/episodes/${episode.uploadDate}`;
  };

  const styles = {
    button: {
      marginLeft: "1rem"
    },

    descriptionStyle: {
      fontSize: "13px",
      textAlign: "left",
      marginTop: "0px",
      marginBottom: "1rem"
    },
    hostNameText: {
      fontSize: "15px",
      textAlign: "left",
      marginLeft: "1rem",
      color: "rgb(166, 167, 167)"
    },

    epTitleStyle: {
      marginLeft: "1rem",
      color: "rgb(166, 167, 167)",
      letterSpacing: "1px"
    },

    showNameStyle: {
      marginLeft: "1rem",
      letterSpacing: "1px"
    }
  };

  return (
    <div className="episodeCard">
      <div className="episode">
        <img src={episode.epArtUrl} alt="Episode Artwork" />
        <div
          style={styles.button}
          className={
            url === episode.epAudioUrl && playing
              ? "episodePauseButton"
              : "episodePlayButton"
          }
          onClick={() => playHandler()}
        ></div>
        <div style={{ display: "block" }}>
          <Link
            to={hostNameLink(episode.hostName)}
            onClick={() => console.log(hostNameLink)}
          >
            <p style={styles.hostNameText}>{episode.hostName}</p>
          </Link>
        </div>
        <div
          className={
            showDescription ? "descrption-button-up" : "descrption-button-down"
          }
          onClick={() => setShowDescription(!showDescription)}
        ></div>
      </div>
      <div className="episodeTitlesMobile">
        <p style={styles.showNameStyle}>{episode.showName} </p>
        <Link to={episodeLink()}>
          {" "}
          <p style={styles.epTitleStyle}> {episode.epTitle}</p>
        </Link>
      </div>

      <SlideDown closed={!showDescription}>
        <h3 style={styles.descriptionStyle} className="episode-description">
          {episode.epDescription}
        </h3>
        <div
          className="socialIcons"
          id="shareIcon"
          onClick={() => shareHandler("episodes", episode.uploadDate)}
          style={{ marginTop: "1rem", marginBottom: "1rem", marginLeft: "0px" }}
        />
      </SlideDown>
    </div>
  );
};

export default EpisodeItem;
