import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import EpisodeItem from "./EpisodeItem";
import EpisodeItemMobile from "./EpisodeItemMobile";
import Select from "react-select";
import Spinner from "./Spinner";

class DisplayEpisodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastevaluatedkey: "empty",
      episodes: [],
      limit: 50,
      noEpisodes: true,
      loading: true,
      show: "",
      host: ""
    };
  }

  componentDidMount() {
    this.getEpisodes(this.state.limit);
    this.getHosts();
    this.getShows();
  }

  getEpisodes(limit) {
    const headers = {
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      userID: process.env.REACT_APP_USER_ID
    };

    axios
      .get(
        `https://api.thesignal.app/web/episodes/?limit=${limit}&lastevaluatedkey=${this.state.lastevaluatedkey}`,
        { headers }
      )
      .then(response => {
        this.setState({
          episodes: response.data.Items,
          noEpisodes: false,
          loading: false
        });
      })
      .catch(err => {
        alert(err);
      });
  }

  getHosts() {
    const headers = {
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      userID: process.env.REACT_APP_USER_ID
    };

    axios
      .get(`https://api.thesignal.app/mobile/hosts/`, { headers })
      .then(response => {
        this.setState({
          hosts: response.data
        });
      })
      .catch(err => {
        alert(err);
      });
  }

  getShows() {
    const headers = {
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      userID: process.env.REACT_APP_USER_ID
    };

    axios
      .get(`https://api.thesignal.app/mobile/shows/`, { headers })
      .then(response => {
        this.setState({
          shows: response.data
        });
      })
      .catch(err => {
        alert(err);
      });
  }

  getHostEpisodes(host) {
    this.setState({ loading: true });
    const headers = {
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      userID: process.env.REACT_APP_USER_ID
    };

    host = escape(host);

    axios
      .get(`https://api.thesignal.app/web/hosts/episodes/?hostName=${host}`, {
        headers
      })
      .then(response => {
        if (response.data.length) {
          this.setState({
            episodes: response.data,
            noEpisodes: false,
            loading: false
          });
        } else {
          this.setState({
            noEpisodes: true,
            loading: false
          });
        }
      })
      .catch(err => {
        alert(err);
      });
  }

  getShowEpisodes(show) {
    this.setState({ loading: true });
    const headers = {
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      userID: process.env.REACT_APP_USER_ID
    };

    show = escape(show);

    axios
      .get(`https://api.thesignal.app/web/shows/episodes/?showName=${show}`, {
        headers
      })
      .then(response => {
        if (response.data.length) {
          this.setState({
            episodes: response.data,
            noEpisodes: false,
            loading: false
          });
        } else {
          this.setState({
            noEpisodes: true,
            loading: false
          });
        }
      })
      .catch(err => {
        alert(err);
      });
  }

  render() {
    var episodes = this.state.episodes;
    var hostOptions = [{ label: "Host", value: "" }];
    var showOptions = [{ label: "Show", value: "" }];

    if (this.state.hosts) {
      hostOptions = this.state.hosts.map(v => ({
        label: v.name,
        value: v.name
      }));
    }

    if (this.state.shows) {
      showOptions = this.state.shows.map(v => ({
        label: `${v.name} with ${v.host}`,
        value: v.name
      }));
    }

    return (
      <div>
        <div className="searchFeatures">
          <div className="searchOption">
            <Select
              options={hostOptions}
              clearable={true}
              className="selectOption"
              name="hostName"
              value={this.state.host}
              placeholder="Search By Host"
              onChange={(value, action) => {
                this.getHostEpisodes(value.value);
                this.setState({
                  host: { label: value.value, value: value.value },
                  show: ""
                });
              }}
            ></Select>
          </div>
          <div className="searchOption">
            <Select
              options={showOptions}
              clearable={true}
              value={this.state.show}
              className="selectOption"
              name="showName"
              placeholder="Search By Show"
              onChange={(value, action) => {
                this.getShowEpisodes(value.value);
                this.setState({
                  show: { label: value.value, value: value.value },
                  host: ""
                });
              }}
            ></Select>
          </div>
        </div>
        {this.props.isMobile ? (
          <div>
            {this.state.loading ? (
              <Spinner />
            ) : (
              <div>
                {this.state.noEpisodes ? (
                  <h3 style={{ width: "100%" }}>
                    Sorry etc, there are no episodes matching this search.
                  </h3>
                ) : (
                  <div>
                    {episodes.length ? (
                      episodes.map(episode => (
                        <EpisodeItemMobile
                          episode={episode}
                          key={episode.uploadDate}
                          play={this.props.play}
                          pause={this.props.pause}
                          url={this.props.url}
                          playing={this.props.playing}
                        />
                      ))
                    ) : (
                      <Spinner />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div>
            {this.state.loading ? (
              <Spinner />
            ) : (
              <div>
                {this.state.noEpisodes ? (
                  <h3 style={{ width: "100%" }}>
                    Sorry etc, there are no episodes matching this search.
                  </h3>
                ) : (
                  <div>
                    {episodes.length ? (
                      episodes.map(episode => (
                        <EpisodeItem
                          episode={episode}
                          key={episode.uploadDate}
                          play={this.props.play}
                          pause={this.props.pause}
                          url={this.props.url}
                          playing={this.props.playing}
                        />
                      ))
                    ) : (
                      <Spinner />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default DisplayEpisodes;
