import React, { Component } from "react";
import EpisodeItem from "./EpisodeItem";
import EpisodeItemMobile from "./EpisodeItemMobile";

class HostPageEpisodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastevaluatedkey: "empty",
      episodes: [],
      limit: 50,
      noEpisodes: false,
      loading: false,
      show: "",
      host: ""
    };
  }

  componentDidMount() {}

  render() {
    var episodes = this.props.episodes;

    return (
      <div>
        {this.props.isMobile ? (
          <div>
            {this.state.noEpisodes ? (
              <h3 style={{ width: "100%" }}>
                Sorry etc, there are no episodes for this host yet.
              </h3>
            ) : (
              <div>
                {episodes.length
                  ? episodes.map(episode => (
                      <EpisodeItemMobile
                        episode={episode}
                        key={episode.uploadDate}
                        play={this.props.play}
                        pause={this.props.pause}
                        url={this.props.url}
                        playing={this.props.playing}
                      />
                    ))
                  : null}
              </div>
            )}
          </div>
        ) : (
          <div>
            {this.state.noEpisodes ? (
              <h3 style={{ width: "100%" }}>
                Sorry etc, there are no episodes for this host yet.
              </h3>
            ) : (
              <div>
                {episodes.length
                  ? episodes.map(episode => (
                      <EpisodeItem
                        episode={episode}
                        key={episode.uploadDate}
                        play={this.props.play}
                        pause={this.props.pause}
                        url={this.props.url}
                        playing={this.props.playing}
                      />
                    ))
                  : null}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default HostPageEpisodes;
